import axios from "axios";
import {getStaff} from "../../AllRoutes/ApiRoute";
import {TeacherSlice} from "../Slice/teacherSlice";
import {StaffSlice} from "../Slice/StaffSlice";

export const getStaffAction = payload => dispatch=>{
    axios.get(getStaff).then(res => {
        if (res.data.status === true) {
            dispatch(StaffSlice.actions.getAllStaffReducers(res.data.data || []))
        }
    }).catch(err => {
        console.log(err)
    });
}