import React from 'react';
import icon01 from '../../assets/images/why-icon-01.png';
import icon02 from '../../assets/images/why-icon-02.png';
import icon03 from '../../assets/images/why-icon-03-1.png';


function WhyChoose(props) {
    return (
        <>
            <h2>Why <samp>Choose</samp> Us</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, Lorem Ipsum has been the industry’s standard</p>
            <ul>
                <li>
                    <img src={icon01} alt=""/> <strong>Expert Employee</strong><br/>Lorem Ipsum is simply dummy tIpsum has been the industry’s Lorem Ipsum is simply dummy tIpsum has been the industry’s
                </li>
                <li>
                    <img src={icon02} alt=""/> <strong>Good Islamic Culture</strong><br/>Lorem Ipsum is simply dummy tIpsum has been the industry’s Lorem Ipsum is simply dummy tIpsum has been the industry’s
                </li>
                <li>
                    <img src={icon03} alt=""/> <strong>Certified & Awards Winner</strong><br/>Lorem Ipsum is simply dummy tIpsum has been the industry’s Lorem Ipsum is simply dummy tIpsum has been the industry’s
                </li>
            </ul>
        </>
    );
}

export default WhyChoose;