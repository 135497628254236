import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import style1 from '../../assets/images/style-01.jpg'
import { FaArrowCircleRight } from "react-icons/fa";
import welcome_img from '../../assets/images/welcome-img.png'

function WelcomeSectionComponent(props) {
    return (
        <>
            <section className="welcomeSection">
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h1>Welcome to <span>Khadijatul Qubra Balika Yatimkhana & Madrasah</span></h1>
                            <p>Our purpose is to provide a safe, happy environment for  foundation needed to achieve</p>
                            <img src={style1} alt=" "/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={6} md={6} sm={12}>
                            <img src={welcome_img} className="mb-4"/>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. <br/><br/>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.<br/>
                            <a href="#">Read More <FaArrowCircleRight/></a>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default WelcomeSectionComponent;