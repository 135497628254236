import axios from "axios";
import {getAllNotice} from "../../AllRoutes/ApiRoute";
import {StaffSlice} from "../Slice/StaffSlice";
import {NoticeSlice} from "../Slice/NoticeSlice";

//Get Notice
export const getAllNoticeAction = payload => dispatch =>{
    axios.get(getAllNotice).then(res => {
        const {setNotice} = payload
        if (res.data.status === true) {
            setNotice(res.data.data)
            dispatch(NoticeSlice.actions.getAllNoticereducers(res.data.data || []))
        }
    }).catch(err => {
        console.log(err)
    });
}