import React from 'react';
import {
    Routes,
    Route, Link,
} from "react-router-dom";
import HomePage from "../Pages/HomePage";
import AisjPage from "../Pages/AisjPage";
import FounderPage from "../Pages/FounderPage";
import DirectorPage from "../Pages/DirectorPage";
import StaringBodyPage from "../Pages/StaringBodyPage";
import EducationManagementPage from "../Pages/EducationManagementPage";
import BoardPage from "../Pages/BoardPage";
import SyllabusPage from "../Pages/SyllabusPage";
import ExamPage from "../Pages/ExamPage";
import ExamResultsPage from "../Pages/ExamResultsPage";
import MeritAwardPage from "../Pages/MeritAwardPage";
import GuardianVisitorPage from "../Pages/GuardianVisitorPage";
import AdmissionPage from "../Pages/AdmissionPage";
import ReAdmissionPage from "../Pages/ReAdmissionPage";
import TeacherPage from "../Pages/TeacherPage";
import StaffPage from "../Pages/StaffPage";
import YearlyFestivalPage from "../Pages/YearlyFestivalPage";
import AnnualFestivalPage from "../Pages/AnnualFestivalPage";
import ELibraryPage from "../Pages/eLibraryPage";
import ContactPage from "../Pages/ContactPage";
import NoticeDetailsPage from "../Pages/NoticeDetailsPage";

function AppRoute(props) {
    return (
        <>
            <Routes>
                <Route path='/' element={<HomePage/>}/>
                <Route path='/AISJ' element={<AisjPage/>}/>
                <Route path='/Founder' element={<FounderPage/>}/>
                <Route path='/Director' element={<DirectorPage/>}/>
                <Route path='/Staring-body' element={<StaringBodyPage/>}/>
                <Route path='/Education-management' element={<EducationManagementPage/>}/>
                <Route path='/Board' element={<BoardPage/>}/>
                <Route path='/Syllabus' element={<SyllabusPage/>}/>
                <Route path='/Exam' element={<ExamPage/>}/>
                <Route path='/Exam-results' element={<ExamResultsPage/>}/>
                <Route path='/Merit-award' element={<MeritAwardPage/>}/>
                <Route path='/Guardian-visitor' element={<GuardianVisitorPage/>}/>
                <Route path='/Admission' element={<AdmissionPage/>}/>
                <Route path='/Re-admission' element={<ReAdmissionPage/>}/>
                <Route path='/Teacher' element={<TeacherPage/>}/>
                <Route path='/Staff' element={<StaffPage/>}/>
                <Route path='/Yearly-festival' element={<YearlyFestivalPage/>}/>
                <Route path='/Annual-festival' element={<AnnualFestivalPage/>}/>
                <Route path='/eLibrary' element={<ELibraryPage/>}/>
                <Route path='/Contact' element={<ContactPage/>}/>
                <Route path='/NoticeDetails/:id' element={<NoticeDetailsPage/>}/>
            </Routes>
        </>
    );
}

export default AppRoute;