import React from 'react';
import {Container} from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../../assets/images/kqym-logo.png'
import {Link, NavLink} from "react-router-dom";

function MenuBer(props) {
    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="#home"><img src={Logo} alt="Logo"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <NavLink to="/" className= "nav-link">Home</NavLink>
                            <NavDropdown title="About" id="basic-nav-dropdown">
                                <Link className="dropdown-item" to="/AISJ">AISJ</Link>
                                <Link className="dropdown-item" to="/Founder">Founder</Link>
                                <Link className="dropdown-item" to="/Director">Director</Link>
                            </NavDropdown>
                            <NavDropdown title="Management" id="basic-nav-dropdown">
                                <Link className="dropdown-item" to="/Staring-body">Staring Body</Link>
                                <Link className="dropdown-item" to="/Education-management">Education Management</Link>
                            </NavDropdown>
                            <NavDropdown title="Education" id="basic-nav-dropdown">
                                <Link className="dropdown-item" to="/Board">Board</Link>
                                <Link className="dropdown-item" to="/Syllabus">Syllabus</Link>
                                <Link className="dropdown-item" to="/Exam">Exam</Link>
                                <Link className="dropdown-item" to="/Exam-results">Exam Results</Link>
                                <Link className="dropdown-item" to="/Merit-award">Merit Award</Link>
                            </NavDropdown>
                            <NavDropdown title="Student" id="basic-nav-dropdown">
                                <Link className="dropdown-item" to="/Guardian-visitor">Guardian Visitor</Link>
                                <Link className="dropdown-item" to="/Admission">Admission</Link>
                                <Link className="dropdown-item" to="/Re-admission">Re-admission</Link>
                            </NavDropdown>
                            <NavDropdown title="Faculty" id="basic-nav-dropdown">
                                <Link className="dropdown-item" to="/Teacher">Teacher</Link>
                                <Link className="dropdown-item" to="/Staff">Staff</Link>
                            </NavDropdown>
                            <NavDropdown title="Events" id="basic-nav-dropdown">
                                <Link className="dropdown-item" to="/Yearly-festival">Yearly Festival</Link>
                                <Link className="dropdown-item" to="/Annual-festival">Annual Festival</Link>
                            </NavDropdown>
                            <NavLink to="/eLibrary" className= "nav-link">eLibrary</NavLink>
                            <NavLink to="/Member" className= "nav-link">Member</NavLink>
                            <NavLink to="/Contact" className= "nav-link">Contact</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default MenuBer;