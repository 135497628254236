import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import style1 from "../../assets/images/style-01.jpg";
import tImage from "../../assets/images/test-ing.png";

function TestimonialComponent(props) {
    return (
        <>
           <section className="testimonialSection">
               <Container>
                   <Row>
                       <Col className="text-center">
                           <h5>Testimonial</h5>
                           <h2>What Our Student Say</h2>
                           <img src={style1} alt=" "/>
                       </Col>
                   </Row>
                   <Row>
                       <Col lg={4} md={4} sm={12}>
                           <div className="testimonialPart">
                               <img src={tImage} alt=""/>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi viverra volutpat ex, id pellentesque felis volutpat eu. Nullam facilisis at turpis eu faucibus. In dignissim, enim eu ornare aliquet, metus</p>
                               <h4>R. Khatun</h4>
                               <h6>Student</h6>
                           </div>
                       </Col>
                       <Col lg={4} md={4} sm={12}>
                           <div className="testimonialPart">
                               <img src={tImage} alt=""/>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi viverra volutpat ex, id pellentesque felis volutpat eu. Nullam facilisis at turpis eu faucibus. In dignissim, enim eu ornare aliquet, metus</p>
                               <h4>R. Khatun</h4>
                               <h6>Student</h6>
                           </div>
                       </Col>
                       <Col lg={4} md={4} sm={12}>
                           <div className="testimonialPart">
                               <img src={tImage} alt=""/>
                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi viverra volutpat ex, id pellentesque felis volutpat eu. Nullam facilisis at turpis eu faucibus. In dignissim, enim eu ornare aliquet, metus</p>
                               <h4>R. Khatun</h4>
                               <h6>Student</h6>
                           </div>
                       </Col>
                   </Row>
               </Container>
           </section>
        </>
    );
}

export default TestimonialComponent;