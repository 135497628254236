import React from 'react';
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";
import CommingSoonComponent from "../Component/CommingSoonComponent/CommingSoonComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import {NoticeContext} from "../Component/WhyChooseUsSectionComponent/NoticeBoard";
import NoticeDetailsComponent from "../Component/WhyChooseUsSectionComponent/NoticeDetailsComponent";

function NoticeDetailsPage(props) {

    return (
        <>
            <HeaderComponent/>
            <InnerBannerComponent title="Notice Details"/>
            <NoticeDetailsComponent/>
            <FooterComponent/>
        </>
    );
}

export default NoticeDetailsPage;