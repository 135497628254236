import React from 'react';
import { FaFacebookF,FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
function ContactDetails(props) {
    return (
        <>
            <strong>Address:</strong><br/>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br/><br/>

            <strong>Phone:</strong><br/>
            +91 1234567890<br/><br/>

            <strong>Email id :</strong><br/>
            <a href="#">support@domainname.com </a> <br/><br/>
            <div className="sm">
                <a href="#" title="Facebook"><FaFacebookF/></a> <a href="#" title="Twitter"><FaTwitter/></a> <a href="#" title="Instagram"><FaInstagram/></a> <a href="#"title="LinkedIn"><FaLinkedinIn/></a>
            </div>
        </>
    );
}

export default ContactDetails;