import React from 'react';
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import CommingSoonComponent from "../Component/CommingSoonComponent/CommingSoonComponent";

function ELibraryPage(props) {
    return (
        <>
            <HeaderComponent/>
            <InnerBannerComponent title="e-Library"/>
            <CommingSoonComponent/>
            <FooterComponent/>
        </>
    );
}

export default ELibraryPage;