import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import { FaUserAlt,FaRupeeSign } from "react-icons/fa";

function TopBer(props) {
    return (
        <>
            <div className="topBer">
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12} className="topWelcome">Welcome to Khadijatul Kubra Balika Yatimkhana & Madrasah</Col>
                        <Col lg={6} md={6} sm={12} className="topRight"><a href="#" className="donateBtn"><FaRupeeSign /> Donate Now</a> <a href="#"><FaUserAlt /> Online Admission</a>  <a href="#" className="sl"><FaUserAlt /> Student Login</a> </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default TopBer;