import axios from 'axios';
import {getTeacher} from "../../AllRoutes/ApiRoute";
import {TeacherSlice} from '../Slice/teacherSlice'

export const getAllTeacher = payload => dispatch => {


    axios.get(getTeacher).then(res => {
        if (res.data.status === true) {
            dispatch(TeacherSlice.actions.getAllTeacherReducers(res.data.data || []))
        }
        }).catch(err => {
            console.log(err)
        });




}