import React from 'react';
import {Col} from "react-bootstrap";
import team01 from "../../../assets/images/img-team.jpg";
import {useSelector} from "react-redux";
import Loading from "../../Loading/Loading";

function TeacherSubComponent(props) {
    // data getting from store
    const allTeacher = useSelector(state => state.teacherSlice.allTeachers)
console.log(allTeacher)

    if(allTeacher.length === 0){
        return (<Loading/>)
    }
    else {
        return (
            <>
                {allTeacher.map((data, index)=>(
                    <Col lg={3} md={3} sm={12}>
                        <div className="teamBox">
                            <h6>{data.staff_name}</h6>
                            <p>{data.designation}</p>
                            <div className="image_box">
                                <img src={data.image || team01} alt="Abdul Matin" title="Abdul Matin"/>
                            </div>
                        </div>
                    </Col>
                ))}

            </>
        );
    }


}

export default TeacherSubComponent;