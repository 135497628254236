import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function CopyRight(props) {
    return (
        <>
            <div className="CopyRight">
                <Container>
                    <Row>
                        <Col>Copyright © 2023 Khadijatul Qubra Balika Yatimkhana & Madrasah . All Rights Reserved.</Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default CopyRight;
