import {createSlice} from "@reduxjs/toolkit";

export const StaffSlice = createSlice({
    name:"staffSlice",
    initialState:{
        allStaffs:[]
    },
    reducers:{
        getAllStaffReducers:(state, action)=>{
            state.allStaffs = action.payload
        }
    }
})
