import React from 'react';
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";
import AisjContentComponent from "../Component/AisjContentComponent/AisjContentComponent";

function AisjPage(props) {
    return (
        <>
            <HeaderComponent/>
            <InnerBannerComponent title="All India Sunnat Al Jamayat"/>
            <AisjContentComponent/>
            <FooterComponent/>
        </>
    );
}

export default AisjPage;