import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import parse from 'html-react-parser';


function NoticeDetailsComponent(props) {

    let data = localStorage.getItem("singleNotice")
    let detailsNotice = JSON.parse(data)
   const dec = parse(detailsNotice.notice_content);


    return (
        <>
            <section className="innerBodyArea">
                <Container>
                    <Row>
                        <Col>
                            <h2>{detailsNotice.title}</h2>
                            {parse(detailsNotice.notice_content)}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default NoticeDetailsComponent;