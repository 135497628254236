import React from 'react';

function FooterPolicy(props) {
    return (
        <>
            <h4>Admission Policy</h4>
            <ul>
                <li><a href="#">Student Area</a></li>
                <li><a href="#">Our Guidelines</a></li>
                <li><a href="#">Our Procedure</a></li>
                <li><a href="#">Terms and Conditions</a></li>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Donation Terms</a></li>
                <li><a href="#">Admission Result</a></li>
            </ul>

        </>
    );
}

export default FooterPolicy;