import React from 'react';
import footerLogo from '../../assets/images/footer-logo.png'

function FooterAbout(props) {
    return (
        <>
            <img src={footerLogo} alt=""/><br/><br/>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi viverra volutpat ex, id pellentesque felis volutpat eu. Nullam facilisis at turpis eu faucibus. In dignissim, enim eu ornare aliquet, metus</p>
        </>
    );
}

export default FooterAbout;