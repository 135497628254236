import React from 'react';
import loading from '../../assets/images/loading.svg'
import {Col} from "react-bootstrap";

function Loading(props) {
    return (
        <>
         <Col className="text-center">
             <img src={loading} width="150px" height="150px"/>
         </Col>
        </>
    );
}

export default Loading;