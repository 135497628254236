import React from 'react';
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import CommingSoonComponent from "../Component/CommingSoonComponent/CommingSoonComponent";
import StaffSection from "../Component/StaffSection/StaffSection";

function StaffPage(props) {
    return (
        <>
            <HeaderComponent/>
            <InnerBannerComponent title="Staff"/>
            <StaffSection/>
            <FooterComponent/>
        </>
    );
}

export default StaffPage;