import React from 'react';
import {useSelector} from "react-redux";
import Loading from "../../Loading/Loading";
import {Col} from "react-bootstrap";
import team01 from "../../../assets/images/img-team.jpg";

function StaffSubComponent(props) {
    // data getting from store
    const allStaff = useSelector(state => state.staffSlice.allStaffs)

    if(allStaff.length === 0){
        return (
            <>
                <Loading/>
            </>
        )
    }
    else {
        return (
            <>
                {allStaff.map((data, index)=>(
                    <Col lg={3} md={3} sm={12}>
                        <div className="teamBox">
                            <h6>{data.staff_name}</h6>
                            <p>{data.designation}</p>
                            <div className="image_box">
                                <img src={data.image || team01} alt="Abdul Matin" title="Abdul Matin"/>
                            </div>
                        </div>
                    </Col>
                ))}

            </>
        );
    }
}

export default StaffSubComponent;