import React, {createContext, useEffect, useState} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import newsIcon from '../../assets/images/icon-01.png'
import {getStaffAction} from "../../Redux/Action/StaffAction";
import {getAllNoticeAction} from "../../Redux/Action/NoticeAction";
import {useDispatch} from "react-redux";
import data from "bootstrap/js/src/dom/data";
import {Link} from "react-router-dom";


function NoticeBoard(props) {
    const dispatch =useDispatch()
    // State
    const [notice,setNotice]=useState([])
    const [singleNotice,setSingleNotice]=useState({})


    // getting data from db lifecycle
    useEffect(()=>{
        // making payload for get data
        const payload = {
            setNotice
        }
        // action dispatch
        dispatch(getAllNoticeAction(payload))

    },[notice])





    var settings = {
        autoplay:true,
        autoplaySpeed:3000,
        dots: true,
        infinite: true,
        speed: 3000,
        vertical:true,
        verticalSwiping: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>

                <h2>Notice <samp>Board</samp> </h2>
                <div>
                    <Slider {...settings}>

                        {notice.map((data, index)=>(
                            <div className="news">
                                <img src={newsIcon} alt=""/> <strong><Link onClick={e=>{
                                   localStorage.setItem("singleNotice", JSON.stringify(data || {}))
                            }}
                                to={`/NoticeDetails/${data.id}`}
                            >{data.title}</Link> </strong><br/>
                                {data.short_description}
                            </div>
                        ))}

                    </Slider>
                </div>



        </>
    );
}

export default NoticeBoard;