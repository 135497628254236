import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/style.css'
import HomePage from "./Pages/HomePage";
import {HashRouter} from "react-router-dom";
import AppRoute from "./AllRoutes/AppRoute";

function App() {
  return (
      <HashRouter>
          <AppRoute/>
      </HashRouter>
  );
}

export default App;
