import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import WhyChoose from "./WhyChoose";
import NoticeBoard from "./NoticeBoard";

function WhyChooseUsComponent(props) {
    return (
        <>
            <section className="whySection">
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12} className="why">
                            <WhyChoose/>
                        </Col>
                        <Col lg={6} md={6} sm={12} >
                            <div className="noticeBoard">
                                <NoticeBoard/>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default WhyChooseUsComponent;