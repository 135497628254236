import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function CommingSoonComponent(props) {
    return (
        <>
            <section className="innerBodyArea">
                <Container>
                    <Row>
                        <Col>
                            <p>Coming soon...  </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default CommingSoonComponent;