import React from 'react';
import {Col} from "react-bootstrap";
import team01 from '../../assets/images/team-1-min.jpg'

function TeamComponent(props) {
    return (
        <>
            <Col lg={3} md={3} sm={12}>
                <div className="teamBox">
                    <h6>Abdul Matin</h6>
                    <p>Senior Manager</p>
                    <div className="image_box">
                        <img src={team01} alt="Abdul Matin" title="Abdul Matin"/>
                    </div>
                </div>
            </Col>
            <Col lg={3} md={3} sm={12}>
                <div className="teamBox">
                    <h6>Abdul Matin</h6>
                    <p>Senior Manager</p>
                    <div className="image_box">
                        <img src={team01} alt="Abdul Matin" title="Abdul Matin"/>
                    </div>
                </div>
            </Col>
            <Col lg={3} md={3} sm={12}>
                <div className="teamBox">
                    <h6>Abdul Matin</h6>
                    <p>Senior Manager</p>
                    <div className="image_box">
                        <img src={team01} alt="Abdul Matin" title="Abdul Matin"/>
                    </div>
                </div>
            </Col>
            <Col lg={3} md={3} sm={12}>
                <div className="teamBox">
                    <h6>Abdul Matin</h6>
                    <p>Senior Manager</p>
                    <div className="image_box">
                        <img src={team01} alt="Abdul Matin" title="Abdul Matin"/>
                    </div>
                </div>
            </Col>
        </>
    );
}

export default TeamComponent;