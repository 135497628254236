import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import FooterAbout from "./FooterAbout";
import FooterQuickLinks from "./FooterQuickLinks";
import FooterPolicy from "./FooterPolicy";
import ContactDetails from "../ContsctDetailsComponent/ContactDetails";
import CopyRight from "./CopyRight";

function FooterComponent(props) {
    return (
        <>
            <footer>
                <Container>
                    <Row>
                        <Col lg={3} md={3} sm={12}>
                            <FooterAbout/>
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                            <FooterQuickLinks/>
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                            <FooterPolicy/>
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                            <h4>Contact Details</h4>
                            <ContactDetails/>
                        </Col>
                    </Row>
                </Container>
                <CopyRight/>
            </footer>
        </>
    );
}

export default FooterComponent;