import {configureStore} from "@reduxjs/toolkit";
import {TeacherSlice} from "./Slice/teacherSlice";
import {StaffSlice} from "./Slice/StaffSlice";
import {NoticeSlice} from "./Slice/NoticeSlice";

export const store = configureStore({
    devTools: true,
    reducer: {
        teacherSlice: TeacherSlice.reducer,
        staffSlice: StaffSlice.reducer,
        noticeSlice: NoticeSlice.reducer
    }
})