import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Banner1 from '../../assets/images/banner-1.jpg'
import Banner2 from '../../assets/images/banner-2.jpg'
import Banner3 from '../../assets/images/banner-3.jpg'

function AisjGallery(props) {
    return (
        <>
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Banner1}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Banner2}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Banner3}
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>
        </>
    );
}

export default AisjGallery;