import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import TeamComponent from "../TeamComponent/TeamComponent";
import TeacherSubComponent from "./TeacherSubComponent/TeacherSubComponent";
import {getAllTeacher} from "../../Redux/Action/TeacherAction";
import {useDispatch} from "react-redux";

function TeacherSectionComponent(props) {
    // hooks
    const dispatch = useDispatch()
    // State
    const [teacher,setTeacher]=useState([])

    // getting data from db lifecycle
    useEffect(()=>{
        // making payload for get data
        const payload = {
            setTeacher
        }
        // action dispatch
        dispatch(getAllTeacher(payload))

    },[teacher])
    return (
        <>
            <section className="innerBodyArea">
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h5 className="titleSm">Our Teachers</h5>
                            <h2 className="titleBig">Meet Our Experts </h2>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-5">
                        <TeacherSubComponent/>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default TeacherSectionComponent;