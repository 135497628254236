import React from 'react';
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import BannerComponent from "../Component/BannerComponent/BannerComponent";
import WelcomeSectionComponent from "../Component/WelcomeSectionComponenr/WelcomeSectionComponent";
import WhyChooseUsComponent from "../Component/WhyChooseUsSectionComponent/WhyChooseUsComponent";
import TestimonialComponent from "../Component/TestimonialSectionComponent/TestimonialComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";

function HomePage(props) {
    return (
        <>
            <HeaderComponent/>
            <BannerComponent/>
            <WelcomeSectionComponent/>
            <WhyChooseUsComponent/>
            <TestimonialComponent/>
            <FooterComponent/>
        </>
    );
}

export default HomePage;