import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function InnerBannerComponent(props) {
    return (
        <>
            <section className="innerBanner">
                <Container>
                    <Row>
                        <Col>
                            <h1>{props.title}</h1>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default InnerBannerComponent;