import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import TeamComponent from "../TeamComponent/TeamComponent";

function StaringBodyComponent(props) {
    return (
        <section className="innerBodyArea">
            <Container>
                <Row>
                    <Col className="text-center">
                        <h5 className="titleSm">Our Staring Team</h5>
                        <h2 className="titleBig">Meet Our Staring Team </h2>
                    </Col>
                </Row>
                <Row className="mt-4 mb-5">
                    <TeamComponent/>
                </Row>
            </Container>
        </section>
    );
}

export default StaringBodyComponent;