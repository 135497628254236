import React from 'react';
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";
import AisjContentComponent from "../Component/AisjContentComponent/AisjContentComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import DirectorComponent from "../Component/DirectorComponent/DirectorComponent";

function DirectorPage(props) {
    return (
        <>
            <HeaderComponent/>
            <InnerBannerComponent title="Director"/>
            <DirectorComponent/>
            <FooterComponent/>
        </>
    );
}

export default DirectorPage;