import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import StaffSubComponent from "./StaffSubComponent/StaffSubComponent";
import {useDispatch} from "react-redux";

import {getStaffAction} from "../../Redux/Action/StaffAction";

function StaffSection(props) {
    // hooks
    const dispatch = useDispatch()
    // State
    const [staff,setStaff]=useState([])

    // getting data from db lifecycle
    useEffect(()=>{
        // making payload for get data
        const payload = {
            setStaff
        }
        // action dispatch
        dispatch(getStaffAction(payload))

    },[staff])
    return (
        <>
            <section className="innerBodyArea">
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h5 className="titleSm">Our Staff</h5>
                            <h2 className="titleBig">Meet Our Experts </h2>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-5">
                       <StaffSubComponent/>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default StaffSection;