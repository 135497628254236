import React from 'react';

function FooterQuickLinks(props) {
    return (
        <>
            <h4>Quick Links</h4>
            <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">Organizations</a></li>
                <li><a href="#">Academic</a></li>
                <li><a href="#">Results</a></li>
                <li><a href="#">Student</a></li>
                <li><a href="#">Gallery</a></li>
                <li><a href="#">Donation</a></li>
                <li><a href="#">Contact</a></li>
            </ul>
        </>
    );
}

export default FooterQuickLinks;