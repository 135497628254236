import React from 'react';
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";
import AisjContentComponent from "../Component/AisjContentComponent/AisjContentComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import FounderComponent from "../Component/FounderComponent/FounderComponent";

function FounderPage(props) {
    return (
        <>
            <HeaderComponent/>
            <InnerBannerComponent title="Founder"/>
            <FounderComponent/>
            <FooterComponent/>
        </>
    );
}

export default FounderPage;