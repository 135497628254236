import React from 'react';
import TopBer from "./headerSubComponent/TopBer";
import MenuBer from "./headerSubComponent/MenuBer";
import LogoArea from "./headerSubComponent/logoArea";

function HeaderComponent(props) {
    return (
        <>
            <TopBer/>
            <MenuBer/>
        </>
    );
}

export default HeaderComponent;